
import { NextPage } from "next";
import { GetStaticProps } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import {
    getUsers,
    getUsers2,
    getVersion,
    getMusics,
    getUserTopSale,
    getTags,
    getTagVersion,
    getGoodsVersion,
    getGoodsList,
    getGoodsVideoList,
    getTopContent,
    getProductVersion,
    getProductList,
    getGoodsTrend,
    getMusicVersion,
} from "@/service/index";
import { useEffect } from "react";

import { useWidth } from "@/utils/resize";
import dynamic from "next/dynamic";
import { GetServerSideProps } from "next";

const MBanner = dynamic(import("@home3/mobile/mBanner"));
const MTag = dynamic(import("@home3/mobile/mtag"));
const MEcomTrend = dynamic(import("@home3/mobile/mEcom"));
const MGoodRanking = dynamic(import("@home3/mobile/mGoodRanking"));
const MGoodsVideo = dynamic(import("@home3/mobile/mGoodVideo"));
const MContent = dynamic(import("@/components/homeV3/mobile/mContent"));
const MInfluencer = dynamic(import("@/components/homeV3/mobile/mInflucer"));

//pc
const Faqs = dynamic(import("@home/faqs"));
const PcBanner = dynamic(import("@home3/pc/banner"));
const PcReView = dynamic(import("@home3/pc/Reviews"));
const PcEcomTrend = dynamic(import("@home3/pc/ecomTrend"));
const PcIntrodction = dynamic(import("@home3/pc/introduction"));
const PcGoodRanking = dynamic(import("@home3/pc/goodRanking"));
const PcContentRanking = dynamic(import("@home3/pc/contentRanking"));
const PcVideoRanking = dynamic(import("@home3/pc/videoRanking"));
const Home: NextPage<{
    influcerData1: any;
    userData2: any;
    userData3: any;
    musicData1: any;
    tagData2: any;
    goodsData: any;
    goodsVideoData: any;
    topFiveContent: any;
    productData: any;
    trendData: any;
}> = (props) => {

    const router = useRouter();
    const { locale } = router;
    const { width, display } = useWidth();
    const isPc = width >= 1024;

    let {
        influcerData1,
        userData2,
        userData3,
        musicData1,
        tagData2,
        goodsData,
        goodsVideoData,
        topFiveContent,
        productData,
        trendData,
    } = props;

    // console.log(goodsVideoData);

    useEffect(() => {
        // if (token) {
        // }
    });
    return (
        <div>
            <Head>
                {locale === "en" ? (
                    <link rel="canonical" href={`https://www.tikmeta.com/`} />
                ) : null}
                {locale !== "en" ? (
                    <link
                        key={locale}
                        rel="alternate"
                        hrefLang={locale}
                        href={`https://www.tikmeta.com/zh`}
                    />
                ) : null}
                <title>
                    TikMeta | TikTok Analytics &amp; Statistics &amp; Track,
                    Influencer Marketing and Product Selection Platform
                </title>
                <meta
                    name="keywords"
                    content="tikmeta  | tiktok analytics tool  |  influencer marketing  | product selection  | live"
                ></meta>
                <meta
                    name="description"
                    content="TikMeta is TikTok tool for analytics, search for popular videos, music, dances, ads and users. Let you get more followers and get famous "
                />
            </Head>

            {!isPc ? (
                <div style={{ visibility: !display ? "hidden" : "visible" }}>
               
                    {/* 手机端 */}
                             {/* @ts-ignore */}
                    <MBanner />
                             {/* @ts-ignore */}
                    <MTag />
                             {/* @ts-ignore */}
                    <MEcomTrend trendData={trendData} />
                             {/* @ts-ignore */}
                    <MGoodRanking goodsList={goodsData} />
                             {/* @ts-ignore */}
                    <MGoodsVideo
                        goodsVideoData={goodsVideoData}
                        productData={productData}
                    />
         {/* @ts-ignore */}
                    <MInfluencer
                        Data1={influcerData1}
                        Data2={userData2}
                        Data3={userData3}
                    ></MInfluencer>
                             {/* @ts-ignore */}
                    <MContent
                        MusicData={musicData1}
                        TagData={tagData2}
                    ></MContent>
                </div>
            ) : (
                <div className="min-h-[800px]">
                    {/* pc端 */}
                    <div
                        style={{ visibility: !display ? "hidden" : "visible" }}
                    >
                                 {/* @ts-ignore */}
                        <PcBanner />
                                 {/* @ts-ignore */}
                        <PcEcomTrend trendData={trendData} />
                                 {/* @ts-ignore */}
                        <PcIntrodction />
                                 {/* @ts-ignore */}
                        <PcGoodRanking goodsList={goodsData} />
                                 {/* @ts-ignore */}
                        <PcVideoRanking
                            productData={productData}
                            goodsVideoData={goodsVideoData}
                        />
                                 {/* @ts-ignore */}
                        <PcContentRanking topFiveContent={topFiveContent} />
                                 {/* @ts-ignore */}
                        <PcReView />
                                 {/* @ts-ignore */}
                        <Faqs />
                    </div>
                </div>
            )}
        </div>
    );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    // console.log("*****", context.locale);
    const { locale } = context;
    //前五 音乐标签
    let fiveContent = await getTopContent(context);
    let topFiveContent = {};
    if (fiveContent && fiveContent.code == 0) {
        topFiveContent = fiveContent?.data || {};
    }
    //达人
    let influcerVersionData = await getVersion(context);
    let influcerVersion = "";
    if (influcerVersionData && influcerVersionData.code == 0) {
        influcerVersion = influcerVersionData?.data[0]?.version;
    }

    let influcer1 = await getUsers(influcerVersion, context);
    let influcerData1 = [];
    if (influcer1 && influcer1.code == 0) {
        influcerData1 = influcer1.data.slice(0, 3);
    }
    let users2 = await getUsers2(influcerVersion, context);
    let userData2 = [];
    if (users2 && users2.code == 0) {
        userData2 = users2.data.slice(0, 3);
    }
    let users3 = await getUserTopSale(influcerVersion, context);
    let userData3 = [];
    if (users3 && users3.code == 0) {
        userData3 = users3.data.slice(0, 3);
    }

    // console.log(users2);

    //音乐 标签 in music

    let versionTagData = await getTagVersion(context);

    let version1 = "";
    if (versionTagData && versionTagData.code == 0) {
        version1 = versionTagData?.data[0]?.version;
    }

    let versionMusicData = await getMusicVersion(context);

    let versionMusic = "";
    if (versionMusicData && versionMusicData.code == 0) {
        versionMusic = versionMusicData?.data[0]?.version;
    }
    let music1 = await getMusics(versionMusic, context);

    let musicData1 = [];
    if (music1 && music1.code == 0) {
        musicData1 = music1.data.slice(0, 3);
    }
    let tag2 = await getTags(version1, context);
    let tagData2 = [];
    if (tag2 && tag2.code == 0) {
        tagData2 = tag2.data.slice(0, 3);
    }

    // //商品
    // let versionGoodsData = await getGoodsVersion();

    // let version2 = "";
    // if (versionGoodsData && versionGoodsData.code == 0) {
    //     version2 = versionGoodsData?.data[0]?.version;
    // }
    let goods = await getGoodsList({}, context);

    let goodsData = [];
    if (goods && goods.code == 0) {
        goodsData = goods?.data;
    }
    let goodsVidoe = await getGoodsVideoList(context);

    let goodsVideoData = [];
    let productData: any = [];
    if (goodsVidoe && goodsVidoe.code == 0) {
        goodsVideoData = goodsVidoe?.data?.goodsVideoRank;
        productData = goodsVidoe?.data?.productVideoRank;
    }

    //种草视频
    // let version3 = "";
    // let versionProductData = await getProductVersion();

    // if (versionProductData && versionProductData.code == 0) {
    //     version3 = versionProductData?.data[0]?.version;
    // }
    // let products = await getProductList(version3);
    // let productData = [];
    // if (products && products.code == 0) {
    //     productData = products.data.slice(0, 5);
    // }

    //商品趋势

    let Trends = await getGoodsTrend(context);
    let trendData = {};
    // console.log(Trends);

    if (Trends && Trends.code == 0) {
        trendData = Trends.data;
    }

    return {
        props: {
            ...(await serverSideTranslations(context.locale as string, [
                "home",
                "common",
                "nav",
                "footer",
            ])),
            influcerData1,
            userData2,
            userData3,
            musicData1,
            tagData2,
            goodsData,
            goodsVideoData,
            topFiveContent,
            productData,
            trendData,
        },
        // revalidate: 10,
    };
};
export default Home;
